import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Button from "../../components/linkbutton"
import "../../stylesheets/newsdetail.scss"

const NewsDetailPage = ({ data }) => (
  <Layout>
    <SEO
      title="お知らせ"
      description={`道新インタラクティブ株式会社のお問い合わせ詳細ページです。${data.microcmsNews.title}`}
    />
    <section className="newsDetailPage-MainSection">
      <h1 className="newsDetailPage-MainSection-heading">
        {data.microcmsNews.title}
      </h1>
      <p className="newsDetailPage-MainSection-date">
        {data.microcmsNews.created_at}
      </p>
      <div
        className="newsDetailPage-MainSection-contents"
        dangerouslySetInnerHTML={{
          __html: `${data.microcmsNews.contents}`,
        }}
      />
      <div className="newsDetailPage-MainSection-buttonWrapper">
        <Button to="/news" className="Button">
          お知らせ一覧へ戻る
        </Button>
      </div>
      <div className="newsDetailPage-MainSection-buttonWrapper">
        <Button to="/" className="Button Button--primary">
          トップページへ戻る
        </Button>
      </div>
    </section>
  </Layout>
)

export default NewsDetailPage

export const query = graphql`
  query($id: String!) {
    microcmsNews(id: { eq: $id }) {
      newsId
      title
      created_at(formatString: "YYYY/MM/DD")
      contents
    }
  }
`
